const initialState = {
  invoices: [],
  totalInvoices: 0,
  totalPages: 0,
  isLoading: false,
}

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INVOICE_LOADING":
      return {
        ...state,
        isLoading: action.data,
      }
    case `SET_INVOICES`:
      return {
        ...state,
        invoices: action.data,
      }
    case `SET_TOTAL_INVOICES`:
      return {
        ...state,
        totalInvoices: action.data,
      }
    case `SET_TOTAL_PAGES`:
      return {
        ...state,
        totalPages: action.data,
      }
    default:
      return state
  }
}

export default invoicesReducer
