const initialState = {
  customers: [],
  editCustomerLoading: false,
}

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_CUSTOMERS`:
      return {
        ...state,
        customers: action.data,
      }
    case `SET_EDIT_CUSTOMER_LOADING`:
      return {
        ...state,
        editCustomerLoading: action.data,
      }
    default:
      return state
  }
}

export default customerReducer
