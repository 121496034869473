const initialState = {
  products: [],
  editProductLoading: false,
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_PRODUCTS`:
      return {
        ...state,
        products: action.data,
      }
    case `SET_EDIT_PRODUCT_LOADING`:
      return {
        ...state,
        editProductLoading: action.data,
      }

    default:
      return state
  }
}

export default productReducer
