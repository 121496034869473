import {
  createStore as reduxCreateStore,
  applyMiddleware,
  combineReducers,
  compose,
} from "redux"
import thunk from "redux-thunk"
import invoice from "./reducers/invoice"
import invoices from "./reducers/invoices"
import product from "./reducers/product"
import customer from "./reducers/customer"
import auth from "./reducers/auth"
import quotation from "./reducers/quotation"
import user from "./reducers/user"
import deliveryNotes from "./reducers/deliveryNotes"
import paymentReceipts from "./reducers/paymentReceipts"
import creditNotes from "./reducers/creditNotes"

const appReducer = combineReducers({
  invoice,
  invoices,
  product,
  customer,
  user,
  auth,
  quotation,
  deliveryNotes,
  paymentReceipts,
  creditNotes,
})

const composeEnhancers =
  (typeof window === "object" &&
    process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

// const composeEnhancers =
//   typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
//     : compose

const createStore = () =>
  reduxCreateStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)))
export default createStore
