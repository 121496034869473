import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import "react-tagsinput/react-tagsinput.css"
import "react-datepicker/dist/react-datepicker.css";
import "./src/styles/global.scss"

// Set up Redux
import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider
